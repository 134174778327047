import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Adb from "@material-ui/icons/Adb";
import PinDrop from "@material-ui/icons/PinDrop";
import Settings from "@material-ui/icons/Settings";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Choose your journey</h2>
          <h5 className={classes.description}>
            From discovery to implementation, from simple to complex, from
            incremental expansion to full-scale enterprise deployment, we will
            evolve your idea into a successful product.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Software Development"
              description="Custom full stack software solutions, giving you information whenever, wherever and however you need it."
              icon={Adb}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Spatial Services"
              description="Creating a confluence of spatial information, analytics and location intelligence for everyday decision-making."
              icon={PinDrop}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Enterprise Architecture"
              description="Navigating business transformation to leverage modern digital technology patterns and position you for the future."
              icon={Settings}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
