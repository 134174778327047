import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import axios from "axios";

import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(workStyle);

export default function SectionWork() {
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderMessage, setSenderMessage] = useState("");
  const [validForm, setFormValid] = useState(false);

  const classes = useStyles();

  const _SetFormValidity = (pSenderName, pSenderEmail, pSenderMessage) => {
    let valid = false;
    if (pSenderName && pSenderEmail && pSenderMessage) {
      if (
        pSenderName.length >= 2 &&
        pSenderEmail.length >= 5 &&
        pSenderMessage.length >= 15
      ) {
        valid = true;
      }
    }
    setFormValid(valid);
  };
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={8} md={8}>
          <h2 className={classes.title}>Work with us</h2>
          <h4 className={classes.description}>
            We work closely with you from discovery to finished product, with
            thorough dedication to your complete satisfaction in the solution.
            From modernizing or expanding your current applications or custom
            development through a complete life-cycle, we will deliver a result
            that is optimized for you. Please contact us for any of your
            software needs.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (evt) => {
                      setSenderName(evt.target.value);
                      _SetFormValidity(
                        evt.target.value,
                        senderEmail,
                        senderMessage
                      );
                    },
                  }}
                  inputProps={{
                    value: senderName,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (evt) => {
                      setSenderEmail(evt.target.value);
                      _SetFormValidity(
                        senderName,
                        evt.target.value,
                        senderMessage
                      );
                    },
                  }}
                  inputProps={{
                    value: senderEmail,
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                  onChange: (evt) => {
                    setSenderMessage(evt.target.value);
                    _SetFormValidity(senderName, senderEmail, evt.target.value);
                  },
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value: senderMessage,
                }}
              />
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.mrAuto + " " + classes.mlAuto}
              >
                <Button
                  color="primary"
                  disabled={!validForm}
                  onClick={() => {
                    axios
                      .post("https://sapienterra.com/api/Email/Send", {
                        name: senderName,
                        from: senderEmail,
                        message: senderMessage,
                      })
                      .then(function (response) {
                        if (response.data) {
                          setSenderName("");
                          setSenderEmail("");
                          setSenderMessage("");
                          _SetFormValidity();
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  Send Message
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
